import React from "react";
import BannerWrapper from '../BannerWrapper';

const TopBanner = ({
  pageTitle,
  title,
  description,
  OSButton,
  pageType,
  redirectUrl
}) => {
  return (
    <>
      <BannerWrapper>
        <div
          className={` flex flex-col md:flex-row  justify-center items-center h-full py-16  mx-auto`}
        >
          <div
            className={`flex flex-col lg:flex-row  justify-center items-center w-full `}
          >
            <div
              className={`w-full lg:w-5/12 md:mb-32 lg:mb-0 flex flex-col lg:items-end  p-0 h-full `}
            >
              <div
                className={`flex flex-col w-11/12 md:w-10/12  space-y-2 md:space-y-5 mx-auto md:mx-0 items-center lg:items-start  mb-10 md:mb-0`}
              >
                <div
                  className={`text-xs md:text-base font-semibold text-center lg:text-left  opacity-80`}
                >
                  {pageTitle}
                </div>
                <div
                  className={`font-bold text-3xl md:text-6xl  w-full pr-2  md:w-11/12  text-center lg:text-left  `}
                  style={{
                    textAlign: pageType === "left" ? "left" : "justify",
                  }}
                >
                <h1>{title}</h1>
                </div>
                <div
                  className={` text-sm md:text-xl font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0 w-full md:w-1/2 lg:w-10/12  `}
                  style={{ textAlign: "justify" }}
                >
                  {`${description}`} 
                </div>
              </div>
            </div>
            <div
              className={`w-9/12 flex item-center justify-center gap-[10px]  lg:w-7/12 bg-cover  bg-no-repeat p-0 md:my-0 h-full mx-auto`}
            >
              {
                OSButton.map((image,index) => (
                  <img
                    key={index}
                    src={image.image}
                    alt="img banner"
                    onClick={() => redirectUrl(image.type)}
                    className=" m-0 p-0 cursor-pointer"
                    style={{
                      maxHeight: "180px",
                      maxWidth: "180px",
                    }}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </BannerWrapper>
    </>
  );
};

export default TopBanner;
