import React, { useEffect } from "react";
import { useState } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  let { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [defaultLangauge, setDefaultLangauge] = useState({});
  const languages = [
    {
      name: 'french',
      langCode:'fr',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-france-round.svg"
    },
    {
      name: 'english_uk',
      langCode:'en',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-uk-round.svg"
    },
    {
      name: 'spanish',
      langCode:'es',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-spain-round.svg"
    },
    {
      name: 'italian',
      langCode:'it',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-italy-round.svg"
    },
    {
      name: 'portuguese_brazil',
      langCode:'pt',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-brazil-round.svg"
    },
    {
      name: 'german',
      langCode:'de',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-germany-round.svg"
    },
    {
      name: 'dutch',
      langCode:'nl',
      img: "https://d2lxkizvrhu4im.cloudfront.net/icons/flag-of-netherlands-round.svg"
    },
  ];

  useEffect(() => {
    const selectedLanguage = languages.filter(language => language.langCode.includes(i18n.language));
    if (selectedLanguage && selectedLanguage.length) {
      setDefaultLangauge({...selectedLanguage[0]});
    } else {
      setDefaultLangauge({...languages[0]})
    }
  }, []);
  

  const handleLanguageChange = (e, language) => {
		e.stopPropagation();
		e.preventDefault();
		i18n.changeLanguage(language.langCode);
		setDefaultLangauge(language);
	};
  return (
    <>
      <header className={`flex flex-row justify-between h-16 overflow-hidden w-full xl:w-11/12 mx-auto font-sans text-sm`}>
        <Link
          to="/"
          className="flex flex-row justify-center items-center px-2 no-underline text-black"
        >
          <img
            alt="header"
            src={logo}
            className="w-10 m-0"
          />
          <div className="mx-4 font-extrabold text-2xl">
            mereos
          </div>
        </Link>


        <div className="hidden lg:flex flex-row items-center justify-center leading-5 font-semibold">
          <div className=" group border-0 focus:outline-none cursor-pointer">
            <div className="relative flex flex-row items-center mr-6">
              {
                defaultLangauge &&
                <img
                  alt={defaultLangauge.name}
                  src={defaultLangauge.img}
                  className="my-0"
                  title={defaultLangauge.name}
                />
              }
              <img
                alt="header"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow-down_yellow_light_icon.svg.svg"
                className="my-0 ml-2 "
              />
            </div>

            <div className="absolute z-10 hidden group-hover:block">
              <div className="relative right-5 flex flex-col overflow-visible bg-white rounded-lg p-5 space-y-2 font-normal shadow-xl">
                {
                  languages.map((lang, index) => {
                    return lang.langCode !== defaultLangauge.langCode ? (
                      <img
                        key={index}
                        alt={lang.name}
                        src={lang.img}
                        className="my-0 ml-2"
                        title={t(lang.name)}
                        onClick={e => handleLanguageChange(e, lang)}
                      />
                    ) : null
                  })
                }
              </div>
            </div>
          </div>

          {/* <button
            className={`bg-yellow-300 border-none rounded-sm px-5 py-2 whitespace-nowrap focus:outline-none`}
            onClick={() => setModalOpen(!isModalOpen)}
          >
            {t('contact_us')}
          </button> */}
        </div>

        <button
          className="flex lg:hidden flex-col justify-center px-4 pt-8 focus:outline-none"
          onClick={() => setOpen(!isOpen)}
        >
          <img
            src={isOpen ? "https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg" : "https://d2lxkizvrhu4im.cloudfront.net/icons/menu_icon.svg"}
            className="h-10 cursor-pointer "
            alt="menu icon"
          />
        </button>

        {
          isModalOpen ? (
            <dialog
              open
              className="contactPopupFormStatic bg-white text-center rounded-lg space-y-2 shadow-xl z-10 top-20 lg:right-10 absolute mx-auto w-11/12   lg:w-2/4"
            >
              <button className="w-full flex flex-row justify-end focus:outline-none absolute top-3 right-3">
                <img
                  alt="header"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/close_icon.svg"
                  onClick={() => setModalOpen(!isModalOpen)}
                  className="cursor-pointer"
                />
              </button>
              {/* <div className={classes.modalcontactform}>
                <ContactForm />
              </div> */}
              <div className="contact_results_container text-xl font-semibold text-black"></div>
            </dialog>
          ) : (
            ""
          )
        }
      </header>
    </>
  );
};

export default Header;
