import React from 'react'
import logo from '../../assets/images/logo.svg';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  
  return (
    <div className='bg-black p-6 mt-8'>
      <div>
        <Link
          to="/"
          className="flex flex-row justify-center items-center px-2 no-underline text-black"
        >
          <img
            alt="header"
            src={logo}
            className="w-10 m-0"
          />
          <div className="mx-4 font-extrabold text-2xl text-white">
            mereos
          </div>
        </Link>

      </div>
      <div className="flex flex-wrap flex-row justify-center mx-auto my-2 space-x-5">
        <a
          href={'https://mereos.eu/conditions-g%C3%A9n%C3%A9rales'}
          target='_blank'
          className="cursor-pointer text-white" rel="noreferrer"
        >
          {t('terms_and_conditions_of_use')}
        </a>
        <a
          href={'https://mereos.eu/politique-de-cookies'}
          target='_blank'
          className="cursor-pointer text-white" rel="noreferrer"
        >
          Cookies
        </a>
        <a
          href={'https://mereos.eu/mentions-l%C3%A9gales'}
          className="cursor-pointer text-white"
          target='_blank' rel="noreferrer"
        >
          {t('terms_of_use')}
        </a>
        <a
          href={'https://mereos.eu/politique-de-confidentialit%C3%A9'}
          className="cursor-pointer text-white"
          target='_blank' rel="noreferrer"
        >
          {t('privacy_policy')}
        </a>
      </div>
      <div className="flex text-white flex-row justify-center my-2 mx-auto">
        &copy; {new Date().getUTCFullYear()} Mereos
      </div>
    </div>
  )
}

export default Footer