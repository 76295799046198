import React from "react";

const BannerWrapper = ({largeBgImg, nonlargeBgImg, children}) => {
  largeBgImg="https://d2lxkizvrhu4im.cloudfront.net/images/home_top_banner.svg";
  nonlargeBgImg="https://d2lxkizvrhu4im.cloudfront.net/images/top_banner_backround.svg";
  
  return (
    <div
      className={`text-white bg-cover bg-no-repeat `}
      style={{ backgroundImage: `${`url("${largeBgImg}")` || `url("${nonlargeBgImg}")`}` }}
    >
      {children}
    </div>
  )
};

export default BannerWrapper;
