import { useTranslation } from 'react-i18next';
import './App.css';
import TopBanner from './components/TopBanner';
import AppRequirements from './components/AppRequirements';
import Header from './components/Header';
import Footer from './components/Footer';
import playstoreImage from './assets/images/google-playstore.svg'
import iosStoreImage from './assets/images/ios-button.svg'
import { useEffect, useState } from 'react';
import { googlePlayStoreUrl, iosAppStoreUrl } from './utils/constant';
import './utils/i18n';

function App() {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    }
    else {
      setDeviceType('Other');
    }
  }, []);

  const values = [
    {
      title: `${t('your_battery_must_be_above_50')}`,
      description: `${t('ensure_your_device_battery_is_above_50_before_starting_the_exam')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/dart_icon_with_bg.svg"
    },
    {
      title: `${t('enable_airplane_mode')}`,
      description: `${t('before_starting_the_exam_please_enable_airplane')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_with_bg.svg"
    },
    {
      title: `${t('enable_silent_mode')}`,
      description: `${t('please_enable_silent_mode')}`,
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/crown_icon_with_bg.svg"
    }
  ];

  const redrectUserToApp = (type) => {
    if (type === 'Android') {
      window.location.href = googlePlayStoreUrl;
    } else if (type === 'iOS') {
      window.location.href = iosAppStoreUrl;
    } else {
      console.log('Unsupported device type');
    }
  };

  return (
    <div>
      <Header />
      <TopBanner 
        pageType={"left"}
        page={""}
        title={t('download_mereos_app')}
        description={t('download_out_app')}
        OSButton={deviceType === 'Android' ? [
        {
          image:playstoreImage,
          type:'Android'
        }] : 
        deviceType === 'iOS'? [
          {
            image:iosStoreImage,
            type:'iOS'
          }
        ] : 
        [
          {
            image:iosStoreImage,
            type:'iOS'
          },
          {
          image:playstoreImage,
          type:'Android'
          }
        ]}
        redirectUrl={redrectUserToApp}
      />
      <div className='mx-auto w-11/12 lg:w-10/12 mb-[70px]'>
       <AppRequirements values={values} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
